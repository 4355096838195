
import { defineComponent } from "vue";
import PickingJobsHeader from "./PickingJobsHeader.vue";

export default defineComponent({
    name: "PackingJobs",
    components: { PickingJobsHeader },
    data() {
        return {
            list: undefined
        }
    }
});
